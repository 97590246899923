<script lang="ts">
	import { ProgressRadial } from '@skeletonlabs/skeleton';
	export let width = 'w-10';
</script>

<div class="flex flex-row justify-center {$$props.class}">
	<ProgressRadial {width} />
</div>

<style>
</style>
